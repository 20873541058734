
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-property-decorator'
import { eventDate, isoDateToTextDate } from '@/helpers/date'
import router from '@/router'
import { Action } from 'vuex-class'
import { publishEventEcommerce } from '@/services/seller.service'

@Component({
  components: {},
})
export default class EventTemplate extends Vue {
  @Prop() readonly event: any
  @Prop() readonly sellerCode: any

  @Action setEventSelected!: Function

  public confirmLoading: boolean = false
  public slug: string = ''

  dateFormat(isoDate) {
    return isoDateToTextDate(isoDate)
  }

  goToShareMicrosite(event: any) {
    this.$router.push({
      name: 'Compartir Micrositio',
      params: {
        eventName: event.name,
        eventSlug: event.slug,
        sellerCode: this.sellerCode,
        eventId: event.id,
        canEditProducts: event.edit_products,
      },
    })
  }

  goToForm(event: any, action: string, entity: string = 'events') {
    const jwt = localStorage.getItem('access_token')

    window.location.href =
      process.env.VUE_APP_ADMIN_DOMAIN +
      '/' +
      entity +
      '/' +
      action +
      '/' +
      event.id +
      '?jwt=' +
      jwt
  }

  selectEvent() {
    localStorage.setItem('selectedEvent', this.event.id)
    this.setEventSelected(this.event.id)
  }

  openEcommerceModal() {
    this.$refs['ecommerce-modal'].show()
  }

  async confirmAndPublishSlug() {
    try {
      this.confirmLoading = true
      const response = await publishEventEcommerce(this.event.id, this.slug)
      Vue.$toast.success(response.data.message)
      this.$refs['ecommerce-modal'].hide()
    } catch (e) {
      Vue.$toast.error(e.response.data.message)
    } finally {
      this.confirmLoading = true
    }
  }

  get ecommerceUrl() {
    return `${process.env.VUE_APP_DOMAIN_ECOMMERCE}/event/${this.slug}`
  }

  get micrositeUrl() {
    return `${process.env.VUE_APP_DOMAIN_ECOMMERCE}/event/${this.event.slug}`
  }

  get slugRules() {
    return [
      v => !!v || 'El campo no puede estar vacío',
      v =>
        /^[a-z0-9-]*$/.test(v) ||
        'Solo se permiten minúsculas, números y guiones',
    ]
  }

  formatSlug() {
    this.slug = this.slug
      .toLowerCase()
      .replace(/\s+/g, '')
      .replace(/[^\w-]/g, '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  }

  get isSlugValid() {
    const regex = /^[a-z0-9-]*$/
    return regex.test(this.slug)
  }
}
